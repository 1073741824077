import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'

export const WEB_SERVICE_CHANNEL = 'Web Desktop'
export const WEB = 'web'
export const PERIPERI = 'periperi'
export const MIN_ORDER = 300
export const DEFAULT_COUNTRY_CODE = 'ph'
export const ONLINE_SOURCE_CODE = 3
export const GENERIC_FAIL_MESSAGE = 'Sorry, something went wrong.'
export const DELIVERY = 'delivery'
export const SEND_SHAKEYS = 'send_shakeys'
export const PICKUP = 'pickup'
export const PRODUCT_PER_PAGE = 6
export const PAST_ORDER_STATUS = 'completed, cancelled'
export const AUTH_PLATFORM = 'periperi-web'
export const ORDER_PROCESSING = 'processing'
export const ORDER_PAYMENT_PENDING_PAYMENT = 'order_pending_payment'
export const ORDER_PENDING = 'pending'
export const CHECK_ORDER_INTERVAL = 5000
export const COD = 'cod'
export const GCASH = 'paymongo_gcash'
export const PAYMAYA = 'paymaya'
export const PAYPAL = 'paypal'
export const CARD = 'paymongo_card'
export const GRABPAY = 'paymongo_grab_pay'
export const COPYRIGHT = '© Peri-Peri 2023'
export const STORE_LOCATOR_AVAILABLE = true
export const SINGLE_OPTION_TYPE = 'single_product'
export const RADIO_OPTION_TYPE = 'radio'
export const PROMO_BRAND = 'periperi'
export const PROMO_CLIENT = 'Peri_web'
export const SC_TEST_DOB = '1900-01-01'
export const SC_ORIGIN =
  typeof window !== 'undefined' && window.innerWidth < 768
    ? 'PERIPERI_WEB_MOBILE'
    : 'PERIPERI_WEB_DESKTOP'
export const PAYMONGO_BASE_RETURN_URL =
  process.env.NEXT_PUBLIC_ORDER_SERVICE_URL
export const AUTH_FLAGS = {
  ACCOUNT_PERMISSION_REQUIRED: 'ACCOUNT_PERMISSION_REQUIRED',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS'
}
export const failedPaymentMsg = {
  title: 'Your Card Was Declined',
  message: 'Please try another card or contact your card provider.'
}

export const getOM = (om) => {
  const oms = {
    [DELIVERY]: 'Delivery',
    [SEND_SHAKEYS]: 'Send-A-Shakeys',
    [PICKUP]: "Pick-up a Peri's"
  }
  return om ? oms[om] : oms
}

export const omc = [
  {
    code: 'delivery',
    name: 'Delivery',
    description: 'Have your orders delivered to you',
    icon: '/images/order-mode-delivery-icon.png',
    selected_icon: '/images/order-mode-delivery-selected-icon.png'
  },
  {
    code: 'send_shakeys',
    name: "Send-a-Shakey's",
    description: 'Have your orders delivered to others',
    icon: '/images/order-mode-send_shakeys-icon.png',
    selected_icon: '/images/order-mode-send_shakeys-selected-icon.png'
  }
]
export const paymentMethodsList = [
  { code: COD, name: 'Cash on Delivery' },
  { code: PAYMAYA, name: 'Credit card or debit card online' },
  { code: CARD, name: 'Credit or Debit Card' },
  { code: GRABPAY, name: 'GrabPay' },
  { code: GCASH, name: 'GCash' }
]

export const paymentMethods = [
  { code: COD, name: 'Cash on Delivery', img: '/images/payments/cash.png' },
  {
    code: CARD,
    name: 'Credit or Debit Card',
    img: '/images/payments/master-card.png'
  },
  { code: GCASH, name: 'GCash', img: '/icons/payment-gcash-icon.png' },
  { code: GRABPAY, name: 'Grab Pay', img: '/icons/grab-pay.png' }
]

export const adBannerTop = {
  link: '/supercard-exclusives',
  bg: '/images/supercard-bg-banner.png',
  text: {
    center: true,
    title: 'Free Pizza. Chicken. Plus More!',
    description:
      'SuperCard Members enjoy these benefits from Shakey’s and Peri-Peri Charcoal Chicken and Sauce Bar'
  },
  btnText: 'Know More'
}

export const adBannerBtm = {
  link: 'https://www.shakeyspizza.ph/',
  bg: '/images/banner-footer.png',
  text: {
    center: true,
    title: 'Shakey’s Pizza Parlor',
    description:
      'We have opened more stores and extended our store hours to give you more Fun, Family, Pizza! DINE-IN & CARRY OUT'
  },
  btnText: 'Visit Store'
}

export const socialMediaLinks = [
  {
    code: 'facebook',
    name: 'Facebook',
    link: 'https://facebook.com/peripericharcoalchicken/',
    icon: <FaFacebookF />
  },
  {
    code: 'instagram',
    name: 'Instagram',
    link: 'https://www.instagram.com/pericharcoalph/',
    icon: <FaInstagram />
  },
  {
    code: 'twitter',
    name: 'Twitter',
    link: 'https://twitter.com/pericharcoalph',
    icon: <FaTwitter />
  },
  {
    code: 'youtube',
    name: 'Youtube',
    link: 'https://youtube.com/channel/UCNCK5jYuUEAqQ8y52X21bVg',
    icon: <FaYoutube />
  }
]

export const legalTermsTypes = {
  privacyPolicy: {
    path: 'privacy-policy',
    type: 'privacy_policy'
  },
  termsAndConditions: {
    path: 'terms-and-conditions',
    type: 'terms_and_conditions'
  },
  on_us_policy: {
    path: '',
    type: 'on_us_policy'
  }
}

export const AWS_SECRET_NAME =
  process.env.STAGE === 'development' ? 'uat/artemis' : 'prod/artemis'
export const AWS_SECRET_MAX_ATTEMPT = 3
export const PAYMONGO_SALT =
  '624f07bd0302933787259eb152be1e205a19523f3d433362-paymongo'
export const SC_ORDER_LEGACY = '0' // set to 1 if supercard purchase flow is legacy

export const SC_BUY_REDIRECT = {
  GOLD: '/catalog/864',
  CLASSIC: '/catalog/863'
}

export const superCardCode = {
  gold: {
    sku: 'SCARD-GOLD',
    type: 'gold',
    slug: 'supercard-gold',
    sc_item_sku: 'WTG',
    title: 'SuperCard Gold',
    buy_sc_redirect:
      SC_ORDER_LEGACY === '1'
        ? '/supercard/buy/supercard-gold'
        : SC_BUY_REDIRECT.GOLD,
    price: 999
  },
  classic: {
    sku: 'SCARD-CLC',
    type: 'classic',
    slug: 'supercard-classic',
    sc_item_sku: 'WTC',
    title: 'SuperCard Classic',
    buy_sc_redirect:
      SC_ORDER_LEGACY === '1'
        ? '/supercard/buy/supercard-classic'
        : SC_BUY_REDIRECT.CLASSIC,
    price: 699
  }
}

export const BONANZA_ENROLLMENT_URL = 'https://supercars.mysupercard.ph/enroll/supercard'
export const BONANZA_URL = 'https://supercars.mysupercard.ph'
export const IS_BONANZA_PROMO_ACTIVE = 0 // set to 1 to activate
export const MAX_B1T1 = 3
